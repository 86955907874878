import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import './styles.css';

// import required modules
import { Navigation } from "swiper/modules";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
export default function Projects() {
  return (
    <div className="xl:pt-[60px] max-md:pt-[60px] pb-2 z-20 relative max-xl:px-[3%] flex flex-col justify-center items-center">
      <div className="xl:max-w-[1420px] flex flex-col mr-auto ml-auto relative">
        <div className="mb-[80px] max-w-[1290px]">
          <div>
            <div className="w-[100%]">
              <div className="md:flex max-md:flex-col w-[100%] md:items-center  gap-5 xl:px-[5%]  max-md:mb-[40px] md:mb-[80px]">
                <div className=" md:hidden flex justify-center items-center w-[95vw]  overflow-hidden">
                  <img
                    src={require('../../Assets/about.jpg')}
                    alt=""
                    className="md:hidden object-cover overflow-hidden"
                  />
                </div>
                <img
                  className="md:pt-[10px] lg:w-[40%] xl:w-[50%]  h-auto max-md:hidden md:h-[529px] max-md:w-[100%] object-cover max-md:ml-[0px]  "
                  src={require('../../Assets/about.jpg')}
                  alt=""
                />
                <div className="lg:w-[55%] md:w-[50%]   max-md:w-[100vw] max-md:pt-[30px] md:pt-[10px] xl:pr-[50px] max-lg:pr-[1rem] xl:pl-[44px]">
                  <div className="text-[#333333] w-[100%] pb-[13px] text=[14px] font-bold leading-[24px] uppercase tracking-[2.8px]">
                    our features
                  </div>
                  <h2 className="text-[#00996d] mb-[38px] md:text-[48px] max-md:text-[35px] max-md:leading-[40px] font-bold md:leading-[55px] ">
                    What sets us apart
                  </h2>
                  <div className="mb-[17px] text-[#777777] text-[18px] pr-[1rem]  flex-wrap leading-[31px] tracking-[0.27px] ">
                    What sets us apart is our dedication to purity and
                    sustainability. Our methods are entirely free from
                    pesticides, chemical additives, and even organic sprays,
                    ensuring that your crops are as natural and safe as
                    possible. We also prioritize water conservation, making our
                    systems efficient and environmentally friendly.
                  </div>
                  <ul className=" xl:pl-[52px] max-xl:pl-[15px] list-disc  text-[#777777] text-[18px] leading-[31px] tracking-[0.27px]">
                    <li className="">
                      {" "}
                      <span className="">
                        {" "}
                        <i className=""> </i>{" "}
                      </span>{" "}
                      <span className="">No Pesticides</span>
                    </li>
                    <li className="">
                      {" "}
                      <span className="">
                        {" "}
                        <i className="fas fa-circle"></i>{" "}
                      </span>{" "}
                      <span className="">Water Saving</span>
                    </li>
                    <li className="">
                      {" "}
                      <span className="">
                        {" "}
                        <i aria-hidden="true" className=""></i>{" "}
                      </span>{" "}
                      <span className="">No Chemicals Used</span>
                    </li>
                    <li className="">
                      {" "}
                      <span className="">
                        {" "}
                        <i aria-hidden="true" className=""></i>{" "}
                      </span>{" "}
                      <span className="">No Organic Sprays</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="xl:px-[4%] md:pb-[0rem] max-w-[1320px] xl:justify-center xl:items-center w-[100%] h-[100%]">
                <div className="lg:flex-col max-lg:flex-col gap-5 w-[100%] xl:justify-center xl:items-center">
                  <div className=" lg:w-[70%]">
                    <div className="max-lg:w-[100vw] w-[100%] pt-[10px] xl:pr-[50px] xl:pl-[44px] md:pl-[20px] ">
                      <div className="text-[#333333] pb-[13px] text-[14px] font-bold leading-[24px] uppercase tracking-[2.8px]">
                        OUR TEAM
                      </div>
                      <h2 className="text-[#00996d] mb-[38px] text-[48px] font-bold leading-[55px] ">
                        Experts ready <br />
                        to help
                      </h2>
                      <div className="mb-[17px] lg:w-[100%] max-lg:w-[50vw] max-md:w-[90vw] max-xl:mb-[2rem] flex-wrap text-[#777777] text-[18px] leading-[31px] tracking-[0.27px] ">
                        Our team of experts is always ready to help you with all
                        ideas. Planning, preparation and implementing are our
                        main priorities.
                      </div>
                    </div>
                  </div>
                  <div className="xl:pl-[24px] max-lg:pl-[40px] max-md:pl-[0px] max-xl:pt-[1rem] pb-[0rem] lg:w-[100%] max-lg:w-[95vw] relative ">
                    <div className="flex-col  gap-10 ">
                      <div className=" md:flex max-md:flex-col gap-20 items-center">
                        <div className="flex md:w-[40%]   flex-col m  max-md:w-[95vw] max-md:text-center">
                          <img
                            className="mb-[30px] "
                            src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                            alt=""
                          />
                        </div>
                        <div className="md:w-[50%] pb-[2rem] max-md:w-[95vw] flex flex-col gap-3 ">
                          <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] md:text-left">
                            <span>Prateek Anand Mohan</span>
                          </div>
                          <div className="text-[#333333]  text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase md:text-left">
                            <span>Founder</span>
                          </div>
                          <p className=" lg:w-[100%]   flex-wrap text-[#777777] text-[16px] leading-[28px] tracking-[0.27px]">
                            Meet PRATEEK ANAND MOHAN, the visionary founder of
                            AGROPONICS. With a background in mechanical
                            engineering and specializations in CAD and CAN
                            designing, Prateek is the technological backbone of
                            our company. His expertise and innovative approach
                            have been instrumental in developing the
                            cutting-edge solutions we offer today.
                          </p>
                          <p className=" lg:w-[100%]   flex-wrap text-[#777777] text-[16px] leading-[28px] tracking-[0.27px]">
                            Prateek's passion for sustainable agriculture and
                            advanced technology drives our mission to
                            revolutionize indoor farming. His technical skills
                            ensure that our systems are not only efficient and
                            reliable but also at the forefront of modern
                            agricultural practices. From designing
                            state-of-the-art farming equipment to optimizing our
                            growing processes, Prateek handles every technical
                            aspect with precision and dedication.
                          </p>
                          <p className=" lg:w-[100%] flex-wrap text-[#777777] text-[16px] leading-[28px] tracking-[0.27px]">
                            Under his leadership, Agroponics has grown into a
                            trusted name in the agroponics industry, known for
                            delivering high-quality, eco-friendly farming
                            solutions. Prateek's contributions extend beyond
                            just technology; his commitment to innovation and
                            sustainability inspires our entire team to push the
                            boundaries of what's possible in indoor farming.
                          </p>
                        </div>
                      </div>

                      <div className=" md:flex max-md:flex-col max-md:mt-[2rem] gap-20 items-center">
                        <div className="flex md:w-[40%] flex-col   max-md:w-[95vw] max-md:text-center">
                          <img
                            className="mb-[30px] "
                            src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                            alt=""
                          />
                        </div>
                        <div className="md:w-[50%] max-md:w-[95vw] flex flex-col gap-3 ">
                          <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] md:text-left">
                            <span>Vinayak Ashok Mullur</span>
                          </div>
                          <div className="text-[#333333]  text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase md:text-left">
                            <span>Co-Founder</span>
                          </div>
                          <p className=" lg:w-[100%]   flex-wrap text-[#777777] text-[16px] leading-[28px] tracking-[0.27px]">
                            Introducing Mr. Vinayak Mullur, the co-founder of
                            Agroponics. Hailing from Chikkud in Athani Taluk,
                            Belgaum, Vinayak brings a deep-rooted passion for
                            farming, inspired by his father's work as a farmer.
                            Despite being a B.Sc. dropout, Vinayak's hands-on
                            experience and extensive knowledge of traditional
                            farming practices have been invaluable in shaping
                            our company's vision and direction.
                          </p>
                          <p className=" lg:w-[100%]   flex-wrap text-[#777777] text-[16px] leading-[28px] tracking-[0.27px]">
                            Vinayak's background in farming provides a unique
                            perspective, blending traditional wisdom with modern
                            technology. His journey from the fields of Chikkud
                            to co-founding an innovative indoor farming company
                            is a testament to his dedication and commitment to
                            sustainable agriculture. With a keen understanding
                            of the challenges and rewards of farming, Vinayak
                            has been instrumental in developing practical and
                            effective solutions that benefit both home gardeners
                            and commercial growers.
                          </p>
                          <p className=" lg:w-[100%] flex-wrap text-[#777777] text-[16px] leading-[28px] tracking-[0.27px]">
                            His expertise and passion for farming have been the
                            driving force behind our company's success.
                            Vinayak's deep connection to agriculture and his
                            innovative spirit ensure that Agroponics remains at
                            the forefront of the industry, offering
                            cutting-edge, eco-friendly farming solutions.
                          </p>
                        </div>
                      </div>
                      {/* <div className="flex flex-col max-md:hidden">
                        <img
                          className="mb-[30px] w-[280px] h-[377px]"
                          src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                          alt=""
                        />
                        <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] text-left">
                          <span>Vinayak Mullur</span>
                        </div>
                        <div className="text-[#333333] text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase text-left">
                          <span>Co-Founder</span>
                        </div>
                      </div> */}
                    </div>
                    {/* <FaLongArrowAltLeft className=" swiper-button hidden image-swiper-button-prev absolute xl:right-[5rem] max-md:left-[30%] max-xl:right-[15rem] text-center bottom-0 text-[#312d2d73] hover:text-black transition-all text-[2rem]" />
                    <FaLongArrowAltRight className=" swiper-button hidden image-swiper-button-next absolute xl:right-[2rem] max-md:left-[55%] max-xl:right-[10rem] bottom-0 text-[#312d2d73] hover:text-black transition-all text-[2rem]" /> */}
                    <div>
                      {/* <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        navigation={{
                          nextEl: ".image-swiper-button-next",
                          prevEl: ".image-swiper-button-prev",
                          disabledClass: "swiper-button-disabled",
                        }}
                        modules={[Navigation]}
                        className="mySwiper hidden conatiners"
                      >
                        <SwiperSlide>
                          <div className="flex  gap-10 md:hidden">
                            <div className="flex flex-col max-md:w-[95vw] max-md:text-center">
                              <img
                                className="mb-[30px] md:w-[280px] md:h-[377px]"
                                src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                                alt=""
                              />
                              <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] md:text-left">
                                <span>Prateek Anand Moha</span>
                              </div>
                              <div className="text-[#333333]  text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase md:text-left">
                                <span>Designer</span>
                              </div>
                            </div>

                            <div className="flex flex-col max-md:hidden">
                              <img
                                className="mb-[30px] w-[280px] h-[377px]"
                                src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                                alt=""
                              />
                              <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] text-left">
                                <span>Vinayak Mullur</span>
                              </div>
                              <div className="text-[#333333] text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase text-left">
                                <span>Designer</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="flex md:hidden  gap-10 ">
                            <div className="flex flex-col max-md:w-[95vw] max-md:text-center">
                              <img
                                className="mb-[30px] md:w-[280px] md:h-[377px]"
                                src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                                alt=""
                              />
                              <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] md:text-left">
                                <span>Hank Howard</span>
                              </div>
                              <div className="text-[#333333]  text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase md:text-left">
                                <span>Designer</span>
                              </div>
                            </div>

                            <div className="flex flex-col max-md:hidden">
                              <img
                                className="mb-[30px] w-[280px] h-[377px]"
                                src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-2.4.jpg"
                                alt=""
                              />
                              <div className="text-[#00996d] text-[30px] font-bold mb-[6px] leading-[51px] text-left">
                                <span>Hank Howard</span>
                              </div>
                              <div className="text-[#333333] text-[14px] leading-[24px] tracking-[2.8px] font-bold uppercase text-left">
                                <span>Designer</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
