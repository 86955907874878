import {  motion, useScroll, useTransform } from 'framer-motion';
import React, {useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// import './styles.css';

// import required modules
import { Autoplay} from 'swiper/modules';

export default function Testimonial() {
    // const divRef = useRef(null);
    const { scrollY } = useScroll();
    const divRef = useRef(null);

    // const getPosition = () => {
    //   const { x, y } = divRef.current.getBoundingClientRect();
    //   console.log(`X position: ${x}`);
      console.log(scrollY);
    // };

const y=useTransform(scrollY,[5400,6300],[0,300])
const y2=useTransform(scrollY,[5400,6300],[-50,200])
const y3=useTransform(scrollY,[5400,6300],[0,500])
  return (
      
    <div ref={divRef} className="md:pt-[66px] max-md:pt-[30px] max-md:w-[100vw] relative md:pb-[355px] z-10 flex justify-center items-center" >
    
  
    <motion.img style={{y:y}}  className="absolute z-50 max-xl:hidden top-[-17rem] opacity-[0.7] left-[-5%]" width={'208'} height={'385'} src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/2-4.png" alt="" />
    
      <motion.img style={{y:y2}} className="absolute z-50 max-xl:hidden top-[-15rem]  right-[-2rem]"   src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/1-3.png" alt="" />
      <motion.img style={{y:y3}} className="absolute z-50 max-xl:hidden top-[20%] right-[-2rem]" src="https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/1-2.png" alt="" />
      
      <div className="flex flex-col overflow-visible w-[100vw] relative">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
       
     
        modules={[Autoplay]}
        className="mySwiper bg-transparent xl:max-w-[1320px] py-[2rem] w-[90vw]"
      >
        <SwiperSlide>
        <div className="text-center flex flex-col  justify-center items-center"> 

          <p className="text-[22px] leading-[37px] xl:w-[805px]  justify-center items-center mb-[51px] text-[#333333] text-center font-bold">
            <span>
            "I recently bought a variety of veggies from Agroponics, and I must say, they are the freshest and most flavorful I've ever had! The strawberries were a real treat—juicy and bursting with sweetness. I'll definitely be a repeat customer!"
            </span>
          </p>
          <span className="text-[#00996d]  text-center text-[30px] font-bold leading-[51px]">Nazmeen Maniyar </span>
          <span className="text-[16px] font-bold leading-[24px] tracking-[2.5px] uppercase text-[#333333] ">India</span>
          <span className="text-[#00996d33] text-[300px] font-bold absolute w-full top-[-4rem] leading-[341px]">“</span>
        </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="text-center flex flex-col relative justify-center items-center"> 

          <p className="text-[22px] leading-[37px] xl:w-[805px] justify-center items-center mb-[51px] text-[#333333] text-center font-bold">
            <span>
            "I'm impressed by the quality of produce from Agroponics. The zucchinis and bell peppers were so fresh and crisp, perfect for my recipes. And don't even get me started on the strawberries—they tasted like they were straight from the garden!"
            </span>
          </p>
          <span className="text-[#00996d]  text-center text-[30px] font-bold leading-[51px]">Rohit Pujari </span>
          <span className="text-[16px] font-bold leading-[24px] tracking-[2.5px] uppercase text-[#333333] "> India</span>
          <span className="text-[#00996d33] text-[300px] font-bold absolute w-full top-[-4rem] leading-[341px]">“</span>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="text-center flex flex-col relative justify-center items-center"> 

          <p className="text-[22px] leading-[37px] xl:w-[805px] justify-center items-center mb-[51px] text-[#333333] text-center font-bold">
            <span>
            "As someone who values organic and pesticide-free options, I was thrilled to find Agroponics. The lettuce and basil I purchased were vibrant and delicious. The strawberries were a highlight—absolutely delectable!"
            </span>
          </p>
          <span className="text-[#00996d]  text-center text-[30px] font-bold leading-[51px]">Shivaprasad Sapare</span>
          <span className="text-[16px] font-bold leading-[24px] tracking-[2.5px] uppercase text-[#333333] ">India</span>
          <span className="text-[#00996d33] text-[300px] font-bold absolute w-full top-[-4rem] leading-[341px]">“</span>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="text-center flex flex-col relative justify-center items-center"> 

          <p className="text-[22px] leading-[37px] xl:w-[805px] justify-center items-center mb-[51px] text-[#333333] text-center font-bold">
            <span>
            "I can't say enough good things about the veggies and strawberries from them. The produce was so fresh and full of flavor. It's clear that they take great care in growing their crops. Highly recommend!"
            </span>
          </p>
          <span className="text-[#00996d]  text-center text-[30px] font-bold leading-[51px]">Sneha Anegundi</span>
          <span className="text-[16px] font-bold leading-[24px] tracking-[2.5px] uppercase text-[#333333] ">India</span>
          <span className="text-[#00996d33] text-[300px] font-bold absolute w-full top-[-4rem] leading-[341px]">“</span>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="text-center flex flex-col relative justify-center items-center"> 

          <p className="text-[22px] leading-[37px] xl:w-[805px] justify-center items-center mb-[51px] text-[#333333] text-center font-bold">
            <span>
            "I'm a big fan of homegrown produce, and agroponics did not disappoint. The veggies were crisp and tasty, and the strawberries were simply amazing—sweet and juicy. I'll definitely be buying from them again!"
            </span>
          </p>
          <span className="text-[#00996d]  text-center text-[30px] font-bold leading-[51px]">Sophia Patel</span>
          <span className="text-[16px] font-bold leading-[24px] tracking-[2.5px] uppercase text-[#333333] ">India</span>
          <span className="text-[#00996d33] text-[300px] font-bold absolute w-full top-[-4rem] leading-[341px]">“</span>
        </div>
        </SwiperSlide>
        </Swiper>
      </div>
    </div>
  
  );
}
