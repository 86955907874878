const Data = [
  {
    id: 1,
    title: "Design & planting",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, amet maxime sunt quidem nostrum .",
    image:
      "https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-3.jpg",
  },

  {
    id: 2,
    title: "Garden care",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, amet maxime sunt quidem nostrum .",
    image:
      "https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-3-3.jpg",
  },

  {
    id: 3,
    title: "Smart planning",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, amet maxime sunt quidem nostrum.",
    image:
      "https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-3-2.jpg",
  },

  {
    id: 4,
    title: "Lawn renovation",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, amet maxime sunt quidem nostrum .",
    image:
      "https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-3-4.jpg",
  },

  {
    id: 5,
    title: "Rubbish removal",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, amet maxime sunt quidem nostrum .",
    image:
      "https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-3.1.jpg",
  },

  {
    id: 6,
    title: "Lawn moving",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, amet maxime sunt quidem nostrum .",
    image:
      "https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/Rectangle-3.2-4.jpg",
  },
];
export default Data;
