import { motion } from 'framer-motion'
import React from 'react'
import { BiSolidPhoneCall } from 'react-icons/bi'
import { IoMenu } from 'react-icons/io5'
import { Link } from 'react-router-dom';

export default function Header() {
  function openNav() {
    document.getElementById("myNav").style.width = "100%";
  }
  
  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }
  return (
    <div className='bg-[#255852] text-white flex w-[100%] max-md:mb-[3rem] md:mb-[140px]'>
       <div className='flex justify-between max-md:py-[1rem] items-center px-[5%] w-[100%]'>
       <span className='xl:text-3xl max-xl:text-2xl   font-bold uppercase'>
        <img src={require('../../Assets/agroponic logo (1).png')} className="md:w-[7rem] max-md:w-[4rem]" alt="logo"/>
        </span>
        <div className='md:hidden'>
            <IoMenu  className='text-white  text-[2.5rem]' onClick={()=>openNav()}/>
            </div>
            <div className='flex gap-10 items-center max-md:hidden' >
            <ul className='text-[18px] flex leading-[1.7em] '>
              <li className='px-[2rem] py-[2.5rem] border-t-[0.8rem] border-transparent hover:border-[#113f23]'><Link to='/'>Home</Link> </li>
              <li className='px-[1.5rem] py-[2.5rem] border-t-[0.8rem] border-transparent tracking-wider transition-all hover:border-[#113f23]'><Link to="/about"> About</Link></li>
              {/* <li className='px-[1.5rem] py-[2.5rem] border-t-[0.8rem] border-transparent tracking-wider transition-all hover:border-[#113f23]'><Link to="/service"> Services</Link></li> */}
              <li className='px-[1.5rem] py-[2.5rem] border-t-[0.8rem] border-transparent tracking-wider transition-all hover:border-[#113f23]'><Link to="/products">Products</Link></li>
              {/* <li className='px-[1.5rem] py-[2.5rem] border-t-[0.8rem] border-transparent tracking-wider transition-all hover:border-[#113f23]'>Idea</li> */}
              <li className='px-[1.5rem] py-[2.5rem] border-t-[0.8rem]  tracking-wider transition-all border-[#113f23]'><Link to="contact">Contact</Link></li>
            </ul>
        
            <motion.button initial={{scale:1}}
                 whileHover={{scale:0.9,transition:{duration:1,type:'spring'}}} className='px-[1rem] max-xl:hidden bg-[#00996d]  flex gap-3 h-[2rem] tracking-wider  justify-center items-center border-0  rounded-l-full rounded-r-full'>
                <BiSolidPhoneCall /> <span className="text-sm"><a href="tel:8055666904"> 8055666904</a></span>
                    </motion.button>
         
        </div>
      </div>
      <div id="myNav" className="overlay z-40">
  <div className="closebtn text-white text-xl" onClick={closeNav}>x</div>
  <div className="overlay-content ml-[1rem]">
    <Link to="/" className='border-l-[7px] border-transparent hover:border-[#204916]   mb-[20px] w-[15rem] pl-[1rem]' style={{paddingLeft: "2rem"}}>Home</Link>
    <Link to="/about"  className='border-l-[7px]  border-transparent hover:border-[#204916]  mb-[20px] w-[15rem] pl-[1rem]' style={{paddingLeft: "2rem"}}>About</Link>
    <Link to="/products"  className='border-l-[7px] border-transparent hover:border-[#204916]  mb-[20px] w-[15rem] pl-[1rem]' style={{paddingLeft: "2rem"}}>products</Link>
    <Link to="/contact" className='border-l-[7px] border-[#204916]   mb-[20px] w-[15rem] pl-[1rem]' style={{paddingLeft: "2rem"}}>Contact</Link>
  </div>
</div>
    </div>
  )
}
