const data=[{
    id:1,
    Pid:1,
    productId:1,
    name:'Lights 200w (NightHawk)',
    price:30280,
    image:'p1.jpg',
    type:'commercial'

},
{
    id:2,
    Pid:2,
    productId:1,
    name:'Lightening 800w – LED Indoor Grow Light',
    price:88000,
    image:'p2.jpg',
    type:'commercial'

},

{
    id:3,
    Pid:3,
    productId:1,
    name:'Lights 100w (Harrier)',
    price:16986,
    image:'p3.jpg',
    type:'commercial'

},
{
    id:4,
    Pid:4,
    productId:1,
    name:'Lights 150w (Raptor)',
    price:22516,
    image:'p4.jpg',
    type:'commercial'

},
{
    id:1,
    Pid:5,
    productId:2,
    name:'Blazen Grow Tent 2’x2’x5′',
    price:13760,
    image:'tent1.webp',
    type:'Hybrid'

},
{
    id:2,
    Pid:6,
    productId:2,
    name:'Blazen Grow Tent 2’X3’x5′',
    price:16120,
    image:'tent-2.jpg',
    type:'Hybrid'

},

{
    id:3,
    Pid:7,
    productId:2,
    name:'Blazen Grow Tent 3’x3’x5′',
    price:18480,
    image:'tent-3.jpg',
    type:'Hybrid'

},
{
    id:1,
    Pid:8,
    productId:3,
    name:'Blazen Hydronutes (Bloom & Flower)',
    price:1655,
    image:'nutrients-1.jpg',
    type:'Hybrid'

},
{
    id:2,
    Pid:9,
    productId:3,
    name:'Blazen Hydronutes (Veg & Bloom)',
    price:1655,
    image:'nutrients-2.jpg',
    type:'Hybrid'

},

{
    id:3,
    Pid:10,
    productId:3,
    name:'Blazen Hydronutes (Vegetative)',
    price:1655,
    image:'nutrients-3.jpg',
    type:'Hybrid'

},
{
    id:1,
    Pid:11,
    productId:4,
    name:'Blazen EuroSoil',
    price:6112,
    image:'soil-1.jpg',
    type:'Hybrid'

}
];
export default data;