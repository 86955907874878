import React from 'react'
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import data from "../constants/Product";
import { Link, useParams } from "react-router-dom";
import Footer from '../Footer';
import Header from '../Products/Header';

export default function ProductList() {
  const {productId}=useParams()
  const product =data.filter((data)=>data.productId===Number(productId))
  return (
    <>
    <Header/>
    <div className="flex w-full px-[10%] justify-center items-center  overflow-hidden flex-col pb-[3rem] mb-[150px]">
    <div className="max-w-[1320px] flex justify-center items-center  flex-col ">
      <h1 className="text-[48px] leading-[55px] flex justify-center items-center font-[700] text-[#00996d]">
        Our Product
      </h1>
      <div>
       
          <div className="flex flex-wrap justify-center items-center gap-5 mt-[2rem]">
          {product?.map((data, index) => (
            <Link to={`/product-details/${data?.Pid}`}>
              <Card sx={{maxWidth: 345,width:345,alignItems:'center',justifyItems:'center'}} >
                {/* <CardMedia
                  sx={{ height: 150, width: 245 }}
                  image={require("../Assets/"+data.image)}
                  title={data?.name}
                /> */}
                <img src={require("../../Assets/prodcts/"+data.image)} className={`h-[23rem] object-cover w-[100%] ${data.name==='Tents' && 'ml-[35%]'}`}alt="" />
                <CardContent>
                  <Typography
                    sx={{
                      color: "#00996d",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    {data?.name}
                  </Typography>
                  <Typography
                    sx={{ color: "#00996d " }}
                    gutterBottom
                    variant="h7"
                    component="div"
                  >
                 Rs.{data?.price}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          ))}
          </div>
        
      </div>
     
      
    </div>
  </div>
  <Footer/>
  </>
  )
}
