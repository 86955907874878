import { type } from "@testing-library/user-event/dist/type";

const data=[
    {
        ProductId:1,
        id:1,
        image:'lights.jpg',
        name:'grow lights',
        type:'commercial'
    },
    {
        ProductId:2,
        id:2,
        image:'Tent.jpg',
        name:'Tents',
        type:'Hybrid'
    },
    {
        ProductId:3,
        id:3,
        image:'nutri.png',
        name:'Nutrients',
        type:'Hybrid'

    },
    {
        ProductId:4,
        id:4,
        image:'soil.png',
        name:'Soil',
        type:'Hybrid'
    }
]
export default data;