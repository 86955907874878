

const data = [
  {
    id: 1,
    productId:1,
    type: "commercial",
    image1:
      "p1.jpg",
    image2:
      "grow.jpg",
    image3:
      "gtow2.jpg",
    heading: "Lights 200w (NightHawk)",
    price: "55,690.00",
    content:
      "Achieve remarkable efficiency with an impressive 3.0 umol/j efficacy, backed by a Photosynthetic Photon Flux Density (PPFD) of 1840 at 12 inches for consistent, high-yield crops. With a lifespan exceeding 80,000 hours and a 5-year warranty, our grow light ensures years of reliable operation and peace of mind.",
  },
  {
    id: 2,
    type: "commercial",
    productId:1,
    image1:
      "p2.jpg",
    image2:
      "p21.jpg",
    image3:
      "p22.jpg",
    heading: "Lightening 800w – LED Indoor Grow Light",
    price: "88,000.00",
    content:
      "Achieve exceptional efficiency with an impressive 3.1 umol/j efficacy, delivering a Photosynthetic Photon Flux Density (PPFD) of 2250 at 12 inches. With a lifespan exceeding 80,000 hours and backed by a 5-year warranty, our grow light ensures years of reliable operation and abundant harvests.",
  },
  {
    id: 3,
    type: "commercial",
    productId:1,
    image1:
      "p3.jpg",
    image2:
      "p31.jpg",
    image3:
      "p32.jpg",
    heading: "Lights 100w (Harrier)",
    price: "16,986.00",
    content:
      "Achieve impressive efficacy of 2.6 umol/j and a Photosynthetic Photon Flux Density (PPFD) of 1152 at 12 inches. With an expected lifespan of over 80,000 hours and backed by a 5-year warranty, the Harrier is built to last through countless grow cycles.",
  },
  {
    id: 4,
    type: "commercial",
    productId:1,
    image1:
      "p42.jpg",
    image2:
      "p4.jpg",
    image3:
      "p41.jpg",
    heading: "Lights 150w (Raptor)",
    price: "22,516.00",
    content:
      "Achieve an impressive efficacy of 2.8 umol/j and a Photosynthetic Photon Flux Density (PPFD) of 1289 at 12 inches. With an expected lifespan of over 80,000 hours and backed by a 5-year warranty, the Raptor is built to last through countless grow cycles.",
  },
  
  {
    id: 5,
    type: "Hybrid",
    image1:
      "tent1.webp",
    image2:
      "t2.jpeg",
    image3:
      "t3.jpg",
    heading: "Blazen Grow Tent 2’x2’x5′",
    price: "13,760.00",
    content:
      "The Blazen Photonics grow tent is made from the finest ballistic nylon light proof blackout fabric, industrial high grade zippers, equipped with multiple vents for air inlet and exhaust, a mylar interior for maximum reflection of light, all supported with an interior frame of solid pvc poles.",
  },
  {
    id: 6,
    type: "Hybrid",
    image1:
      "tent-2.jpg",
    image2:
      "tent-21.webp",
    image3:
      "tent-22.jpg",
    heading: "Blazen Grow Tent 2’X3’x5′",
    price: "16,120.00",
    content:
      "The Blazen Photonics grow tent is made from the finest ballistic nylon light proof blackout fabric, industrial high grade zippers, equipped with multiple vents for air inlet and exhaust, a mylar interior for maximum reflection of light, all supported with an interior frame of solid pvc poles.",
  },
  {
    id: 7,
    type: "Hybrid",
    image1:
      "tent-3.jpg",
    image2:
      "tent-31.webp",
    image3:
      "tent-32.webp",
    heading: "Blazen Grow Tent 3’x3’x5′",
    price: "18,480.00",
    content:
      "The Blazen Photonics grow tent is made from the finest ballistic nylon light proof blackout fabric, industrial high grade zippers, equipped with multiple vents for air inlet and exhaust, a mylar interior for maximum reflection of light, all supported with an interior frame of solid pvc poles.",
  },
  {
    id: 8,
    type: "Hybrid",
    image1:
      "n2.png",
    image2:
      "nutri.png",
    image3:
    "nutrients-1.jpg",
    heading: "Blazen Hydronutes (Bloom & Flower)",
    price: "1,655.00",
    content:
      "A perfect blend of nutrients for the perfectionist.  Our handcrafted mix of NPK ratio of 20-10-20-2 with the added kick of cal/mag is a scientific mix that has been derived from more than 30 years of government funded research in Industrial hemp and medical cannabis at Utah State University.",
  },
  {
    id: 9,
    type: "Hybrid",
    image1:
      "n1.png",
    image2:
      "nutri2.png",
    image3:
    "nutrients-2.jpg",
    heading: "Blazen Hydronutes (Veg & Bloom)",
    price: "1,655.00",
    content:
      "A perfect blend of nutrients for the perfectionist.  Our handcrafted mix of NPK ratio of 20-10-20-1 is a scientific mix that has been derived from more than 30 years of government funded research in Industrial hemp and medical cannabis at Utah State University.",
  },
  {
    id: 10,
    type: "Hybrid",
    image1:
      "n3.png",
    image2:
      "nutri3.png",
    image3:
    "nutrients-3.jpg",
    heading: "Blazen Hydronutes (Vegetative)",
    price: "1,655.00",
    content:
      "A perfect blend of nutrients for the perfectionist.  Our handcrafted mix of NPK ratio of 20-10-20  is a scientific mix that has been derived from more than 30 years of government funded research in Industrial hemp and medical cannabis at Utah State University. Make 50 liters of  plant nutrition with the bottle.",
  },
  {
    id: 11,
    type: "Hybrid",
    image1:
      "soil-1.jpg",
    image2:
      "soli1.png",
    image3:
    "soil-1.jpg",
    heading: "Blazen EuroSoil",
    price: "6,112.00",
    content:
      "Artisanal handcrafted soilless mixture of Russian upper peat moss, Lithuanian lower peat moss, dolomite lime, gypsum, and vermiculite.  This premium growing substrate is what serious growers across Europe and USA are using to achieve superior outcomes.  If your going to spend 4 months growing .. you might as well do it right !",
  },
];
export default data;
