import React from 'react'

export default function Banner() {
  return (
    <div className='flex xl:pt-[-2rem] justify-center items-center max-md:mt-[1rem] xl:px-[10%]  max-md:mb-[50px] md:mb-[100px]'>
      <div className='flex max-w-[1320px] max-xl:w-[90%] justify-center items-center'>
        <img src={require('../../Assets/aboutb.jpg')} alt="" className='' />
      </div>
    </div>
  )
}
