import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { FaPlay } from "react-icons/fa";
import { Button } from "@mui/material";

import Dialogs from "./Model";

export default function Projects() {
  const [url, setUrl] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (uri) => {
    setOpen(true);
    setUrl(() => uri);
    console.log(url);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="xl:pt-[101px] max-xl:pt-[100px] max-md:pt-[2rem] pb-2 z-20 relative  flex flex-col justify-center items-center">
      <div className="max-w-[1420px] flex flex-col mr-auto ml-auto relative">
        <div className="w-[100vw] flex box-border xl:px-[10%] max-xl:px-[3%] ">
          <div>
            <div className="text-[#333333] text-[14px] font-bold pb-[12px] uppercase leading-[24px] tracking-[2.8px]">
              Our projects
            </div>
            <div className="md:mb-[79px] max-md:mb-[3rem]">
              <h2 className="text-[#00996d] md:text-[48px] max-md:text-[35px] max-md:leading-[40px] font-bold md:leading-[55px]">
                We handle
                <br />
                everything for you!
              </h2>
            </div>
          </div>
        </div>

        <div className="xl:mb-[80px] max-xl:mb-[50px] max-w-[1290px]">
          <div>
            <div className="w-[95vw]">
              <div className="md:flex max-md:flex-col items-center gap-5 xl:px-[5%] max-xl:px-[3%] w-full max-md:mb-[30px] md:mb-[80px]">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper lg:w-[35%]  md:w-[50%] max-md:w-[100%]"
                >
                  <SwiperSlide className="w-[100%] h-[50%] relative">
                    <img
                      className="w-[100%] "
                      src={require("../Assets/com.jpg")}
                      alt=""
                    />
                    <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                      <Button onClick={() => handleClickOpen("com.mp4")}>
                        <span className="border-0  w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                          <FaPlay className="ml-[1.3rem] mt-[1.2rem] text-[1.5rem]  max-md:text-[1.5rem]" />
                        </span>
                      </Button>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="w-[100%] relative">
                    <img
                      className=" w-[100%] "
                      src={require("../Assets/comm2.jpg")}
                      alt=""
                    />
                    <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                      <Button onClick={() => handleClickOpen("comm2.mp4")}>
                        <span className="border-0  w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                          <FaPlay className="ml-[1.3rem] mt-[1.2rem] text-[1.6rem]  max-md:text-[1.5rem]" />
                        </span>
                      </Button>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="md:w-[50%] pt-[10px] max-md:pt-[1.5rem] xl:pr-[50px] md:pl-[44px]">
                  <h3 className=" text-[#333333] md:mb-[32px] max-md:mb-[20px] text-[30px] font-bold leading-[34px]">
                    Commercial
                  </h3>
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  repellat harum recusandae, magnam in quam aperiam libero
                  expedita? Hic iusto dolorum ipsa blanditiis tenetur minus
                  molestiae quos officiis nesciunt facilis. */}
                  <div className="text-[#777777] text-[18px] leading-[31px] tracking-[0.3px]">
                    Our commercial indoor planting project, centered on
                    strawberries and lettuce, utilizes hydroponic systems and
                    controlled environment agriculture to produce high-quality,
                    pesticide-free produce throughout the year. By employing
                    vertical farming techniques and precise climate control, we
                    ensure optimal growing conditions for both crops. This
                    sustainable approach drastically reduces water usage and
                    eliminates the need for chemical pesticides, ensuring fresh
                    and nutritious strawberries and lettuce are available
                    regardless of seasonal and geographic constraints.
                  </div>
                  {/* <div className="mt-[1rem] flex flex-col">
                    <div className="flex">
                      <span className="mr-[31px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                        Square
                      </span>
                      <span className="text-[#00996d] text-[18px] leading-[40px]">
                        2.474 acres
                      </span>
                    </div>
                    <div className="flex">
                      <span className="mr-[31px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                        Builder
                      </span>
                      <span className="text-[#00996d] text-[18px] leading-[40px]">
                        ADR projects
                      </span>
                    </div>
                    <div className="flex">
                      <span className="mr-[18px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                        Architect
                      </span>
                      <span className="text-[#00996d] text-[18px] leading-[40px]">
                        Geoform landscaping department
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="xl:pl-[13%] max-xl:px-[3%] max-md:w-[95vw] md:pr-[2.3%] max-md:px-[3%] max-w-[1320px] justify-center items-center w-[100vw] h-[100%]">
                <div className="md:flex max-md:flex-col-reverse gap-5 w-[100%] xl:justify-center items-center">
                  <div className="lg:pl-[14px] flex overflow-hidden xl:pl-[24px] md:ml-[5px]   md:w-[550px] md:h-[520px] md:hidden">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper w-[100%]"
                    >
                      <SwiperSlide className="w-[100%] relative">
                        <img
                          loading="lazy"
                          src={require("../Assets/home1.jpg")}
                          alt=""
                          className="w-[100%] max-md:mb-[1.5rem]"
                        />
                        <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                          <Button onClick={() => handleClickOpen("home2.mp4")}>
                            <span className="border-0  w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                              <FaPlay className="ml-[1.3rem] mt-[1.2rem]  max-md:text-[1.5rem]" />
                            </span>
                          </Button>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="relative">
                        <Button onClick={() => handleClickOpen("home.mp4")}>
                          <img
                            loading="lazy"
                            src={require("../Assets/home2.jpg")}
                            alt=""
                            className="w-[100%] max-md:mb-[1.5rem]"
                          />
                          <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                            {/* <Button  onClick={()=>handleClickOpen('mico1.mp4')}}>  */}
                            <span className="border-0  w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                              <FaPlay className="ml-[1.3rem] mt-[1.2rem]  max-md:text-[1.5rem]" />
                            </span>
                            {/* </Button> */}
                          </div>
                        </Button>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div className=" md:w-[60%]">
                    <div className="w-[100%] pt-[10px] xl:pr-[24px] pl-[]">
                      <h3 className=" text-[#333333] md:mb-[32px] max-md:mb-[20px] text-[30px] font-bold leading-[34px]">
                        Small
                      </h3>
                      <div className="text-[#777777] text-[18px] leading-[31px] tracking-[0.3px]">
                        Our small indoor planting project grows strawberries and
                        lettuce using hydroponics. This method provides ideal
                        conditions for producing fresh, pesticide-free crops
                        year-round. By reducing water usage and eliminating
                        chemical pesticides, we showcase sustainable urban
                        farming. Our initiative proves that even on a small
                        scale, it's possible to deliver high-quality produce
                        consistently and responsibly.
                      </div>
                      {/* <div className="mt-[1rem] flex flex-col">
                        <div className="flex">
                          <span className="mr-[31px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                            Square
                          </span>
                          <span className="text-[#00996d] text-[18px] leading-[40px]">
                            2.474 acres
                          </span>
                        </div>
                        <div className="flex">
                          <span className="mr-[31px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                            Builder
                          </span>
                          <span className="text-[#00996d] text-[18px] leading-[40px]">
                            ADR projects
                          </span>
                        </div>
                        <div className="flex">
                          <span className="mr-[18px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                            Architect
                          </span>
                          <span className="text-[#00996d] text-[18px] leading-[40px]">
                            Geoform landscaping department
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="pl-[24px] max-md:hidden relative w-[40%]">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper w-[100%]"
                    >
                      <SwiperSlide className="relative ">
                        <Button onClick={() => handleClickOpen("home2.mp4")}>
                          <img
                            loading="lazy"
                            src={require("../Assets/home1.jpg")}
                            alt=""
                            className="w-[100%] max-md:mb-[1.5rem]"
                          />
                          <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                            <span className="border-0 w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                              <FaPlay className="ml-[1.3rem] mt-[1.2rem] " />
                            </span>
                          </div>
                        </Button>
                      </SwiperSlide>
                      <SwiperSlide className="relative">
                        <Button onClick={() => handleClickOpen("home.mp4")}>
                          <img
                            loading="lazy"
                            src={require("../Assets/home2.jpg")}
                            alt=""
                            className="w-[100%] max-md:mb-[1.5rem]"
                          />
                          <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                            <spann className="border-0 w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                              <FaPlay className="ml-[1.3rem] mt-[1.2rem] " />
                            </spann>
                          </div>
                        </Button>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:px-[11%] w-[98vw] max-md:ml-[1vw] max-md:w-[95vw] max-md:pl-[3%] md:flex max-md:flex-col gap-5 max-w-[1320px] md:mb-[83px] justify-center items-center">
          <div className="pt-[10px] lg:pr-[85px] md:pr-[40px] md:w-[468px] max-md:pr-[3%]  max-md:mr-[1vw] md:h-[550px]">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper w-[100%]  h-[100%]"
            >
              <SwiperSlide className="relative h-[100%]">
                <img
                  loading="lazy"
                  className="w-[100%] h-[100%] object-cover"
                  src={require("../Assets/1.jpg")}
                  alt=""
                />
                <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                  <Button onClick={() => handleClickOpen("mico1.mp4")}>
                    <span className="border-0  w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                      <FaPlay className="ml-[1.3rem] mt-[1.2rem] text-[1.6rem] max-md:text-[1.5rem]" />
                    </span>
                  </Button>
                </div>
              </SwiperSlide>
              <SwiperSlide className="relative">
                <img
                  loading="lazy"
                  className="w-[100%] h-[100%]"
                  src={require("../Assets/2.jpg")}
                  alt=""
                />
                <div className="absolute border-0  top-[42%] left-[45%]  flex justify-center items-center z-40 text-white text-[1.5rem]">
                  <Button onClick={() => handleClickOpen("micro2.mp4")}>
                    <span className="border-0  w-[4rem] h-[4rem]  rounded-full bg-[#12502cd3]">
                      <FaPlay className="ml-[1.3rem] mt-[1.2rem] text-[1.6rem] max-md:text-[1.5rem]" />
                    </span>
                  </Button>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className=" md:w-[50%] md:pl-[24px]">
            <div className="w-[100%] max-md:mt-[1.5rem]  pr-[24px] pl-[]">
              <h3 className=" text-[#333333] md:mb-[32px] max-md:mb-[20px] text-[30px] font-bold leading-[34px]">
                Micro Greens
              </h3>
              <div className="text-[#777777] text-[18px] leading-[31px] tracking-[0.3px]">
                Our microgreens project utilizes advanced hydroponic systems to
                cultivate nutrient-dense greens in a controlled indoor
                environment. This method ensures optimal growth conditions,
                allowing us to produce fresh, pesticide-free microgreens
                year-round. By using minimal water and space, our sustainable
                approach highlights the potential of urban agriculture. This
                project demonstrates that even on a small scale, we can
                consistently provide high-quality, nutritious microgreens while
                promoting environmentally friendly practices.
              </div>
              {/* <div className="mt-[1rem] flex flex-col">
                <div className="flex">
                  <span className="mr-[31px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                    Square
                  </span>
                  <span className="text-[#00996d] text-[18px] leading-[40px]">
                    2.474 acres
                  </span>
                </div>
                <div className="flex">
                  <span className="mr-[31px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                    Builder
                  </span>
                  <span className="text-[#00996d] text-[18px] leading-[40px]">
                    ADR projects
                  </span>
                </div>
                <div className="flex">
                  <span className="mr-[18px] text-[18px] font-bold  text-[#333333] leading-[40px]">
                    Architect
                  </span>
                  <span className="text-[#00996d] text-[18px] leading-[40px]">
                    Geoform landscaping department
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex gap-2 justify-center items-center font-bold text-[18px] cursor-pointer">
        <div className="border-2 w-[1.5rem] h-[1.5rem] bg-green-900 rounded-full text-[18px] font-bold" />{" "}
        More projects
      </div> */}
      <Dialogs
        open={open}
        url={url}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </div>
  );
}
