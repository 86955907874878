import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Dialogs({open,setOpen,handleClose,handleClickOpen,url}) {
  const uri='../Assets/product2/'+url;
console.log(url)


  return (
    <React.Fragment>
     
      <Dialog
        open={open}
        onClose={handleClose}
        width={'xl'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        {url && <video width="370" height={'400'}  controls className='' >
  <source src={require('../Assets/product2/'+url)} type="video/mp4"/>
  
  
        </video>}
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
