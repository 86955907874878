import React from 'react'
import { GiArchiveResearch, GiStrawberry } from "react-icons/gi";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import { GiStrawberry } from "react-icons/gi";
import 'react-vertical-timeline-component/style.min.css';
export default function Journy() {
  return (
    <div className='pb-[3rem] max-md:mt-[-2rem]'>
        <h1 className='text-center text-[#333333] uppercase tracking-[2.8px] pb-[5px] text-[14px] leading-[24px] font-bold'> The Journey of Agroponics Co.</h1>
        <h1 className='text-center text-[#00996d] text-[38px] leading-[55px] pb-[13px] max-md:text-[30px] max-md:leading-[45px] font-bold'> A Timeline of Success</h1>
      <VerticalTimeline  lineColor={ '#00996d' }>
  <VerticalTimelineElement
    className="vertical-timeline-element--work xl:text-black"
    contentStyle={{ background: '#00996d' }}
    contentArrowStyle={{ borderRight: '7px solid  #00996d' }}
    // dateClassName={{color:'black'}}
    date="2019 - 2020"
    iconStyle={{ background: '#00996d', color: 'black' }}
    icon={<GiArchiveResearch  className='text-white '/>}
  >
    <h3 className="vertical-timeline-element-title text-white text-[18px] font-bold"> Research and Development for Leafy Greens</h3>
    {/* <h4 className="vertical-timeline-element-subtitle text-white">Miami, FL</h4> */}
    <p className='text-white'>
    In 2019, Agroponics Co. embarked on its journey of innovation by focusing on research and development for leafy greens, particularly lettuce. Through rigorous experimentation and technological advancements, they successfully cultivated lettuce using indoor farming techniques.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
      className="vertical-timeline-element--work xl:text-black"
      contentStyle={{ background: '#00996d' }}
      contentArrowStyle={{ borderRight: '7px solid  #00996d' }}
      // dateClassName={{color:'black'}}
      date="2020 - 2021"
      iconStyle={{ background: '#00996d', color: 'black' }}
      icon={<GiStrawberry  className='text-white  '/>}
  >
    <h3 className="vertical-timeline-element-title text-white text-[18px] font-bold">Venturing into Strawberries</h3>
    {/* <h4 className="vertical-timeline-element-subtitle text-white">Miami, FL</h4> */}
    <p className='text-white'>
    Building on their success with leafy greens, Agroponics Co. turned its attention to strawberries in 2020. After months of intensive research and development, they achieved remarkable success in growing strawberries using their advanced indoor farming methods.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
   className="vertical-timeline-element--work xl:text-black"
   contentStyle={{ background: '#00996d' }}
   contentArrowStyle={{ borderRight: '7px solid  #00996d' }}
   // dateClassName={{color:'black'}}
   date="2021 - 2022"
   iconStyle={{ background: '#00996d', color: 'black' }}
   icon={<img src={require('../../Assets/vertical-farming (1).png') } className='w-[1.8rem] flex items-center justify-center ml-[1rem] mt-[1rem]'/>}
>
 <h3 className="vertical-timeline-element-title text-white text-[18px] font-bold">Inauguration of Indoor Farm</h3>
 {/* <h4 className="vertical-timeline-element-subtitle text-white">Miami, FL</h4> */}
 <p className='text-white'>
 The year 2021 marked a significant milestone for Agroponics Co. as they inaugurated their state-of-the-art indoor farm. This facility symbolized their dedication to sustainable agriculture and showcased their expertise in high-tech farming methods.
 </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work xl:text-black"
    contentStyle={{ background: '#00996d' }}
    contentArrowStyle={{ borderRight: '7px solid  #00996d' }}
    // dateClassName={{color:'black'}}
    date="2022 - 2023"
    iconStyle={{ background: '#00996d', color: 'black' }}
    icon={<img src={require('../../Assets/promotion.png') } className='w-[1.8rem] flex items-center justify-center ml-[1rem] mt-[1rem]'/>}
 >
  <h3 className="vertical-timeline-element-title text-white text-[18px] font-bold"> Commercial Success with Strawberries</h3>
  {/* <h4 className="vertical-timeline-element-subtitle text-white">Miami, FL</h4> */}
  <p className='text-white'>
  In 2022, Agroponics Co. achieved its first major commercial success with a high-tech indoor farm dedicated to strawberries. This successful project demonstrated their capabilities in delivering efficient and productive farming solutions to commercial clients.
  </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
     className="vertical-timeline-element--work xl:text-black"
     contentStyle={{ background: '#00996d' }}
     contentArrowStyle={{ borderRight: '7px solid  #00996d' }}
     // dateClassName={{color:'black'}}
     date="2023 - 2024"
     iconStyle={{ background: '#00996d', color: 'black' }}
     icon={<img src={require('../../Assets/innovation.png') } className='w-[1.8rem] flex items-center justify-center ml-[1rem] mt-[1rem]'/>}
  >
   <h3 className="vertical-timeline-element-title text-white text-[18px] font-bold">  Hybrid Farming Projects</h3>
   {/* <h4 className="vertical-timeline-element-subtitle text-white">Miami, FL</h4> */}
   <p className='text-white'>
   In 2023, Agroponics Co. pioneered hybrid farming projects, leveraging a combination of natural sunlight and artificial grow lights. This innovative approach maximized crop yields while ensuring the highest quality produce. The synergy between sunlight and grow lights optimized plant growth and nutritional content, setting new standards in indoor farming excellence. In addition to commercial ventures, Agroponics Co. also focused on homegrown projects in 2023. They successfully grew a variety of crops including basil, zucchini, and bell peppers, enriching the lives of home gardeners with fresh and healthy produce.
   </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
   className="vertical-timeline-element--work xl:text-black"
   contentStyle={{ background: '#00996d' }}
   contentArrowStyle={{ borderRight: '7px solid  #00996d' }}
   // dateClassName={{color:'black'}}
   date="2024 - Present"
   iconStyle={{ background: '#00996d', color: 'black' }}
   icon={<img src={require('../../Assets/expansion.png') } className='w-[1.8rem] flex items-center justify-center ml-[1rem] mt-[1rem]'/>}
>
 <h3 className="vertical-timeline-element-title text-white text-[18px] font-bold">  International Expansion</h3>
 {/* <h4 className="vertical-timeline-element-subtitle text-white">Miami, FL</h4> */}
 <p className='text-white'>
 The year 2024 marked a new chapter as Agroponics Co. ventured into international markets with a commercial project in Thailand. This expansion showcased our global vision and commitment to bringing sustainable farming practices to diverse communities.
 </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    iconStyle={{ background: '#00996d', color: '#fff' }}
    icon={<img src={require('../../Assets/moving.png') } className='w-[1.8rem] flex items-center justify-center ml-[1rem] mt-[1rem]'/>}
    // icon={<StarIcon />}
  />
</VerticalTimeline>
    </div>
  )
}
